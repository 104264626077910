const SANS = `'danzza', sans-serif`
const SERIF = `'cardinal', Georgia,Times, serif`
const SERIFALTERNATIVE = `'cardinalfruit', serif`

export const fonts = {
  heading01: {
    fontSize: 13,
    lineHeight: 25 / 13,
    fontFamily: SANS
  },
  heading02: {
    fontSize: 18,
    lineHeight: 25 / 18,
    fontFamily: SERIF,
    fontStyle: 'italic'
  },
  heading03: {
    fontSize: 15,
    lineHeight: 20 / 15,
    fontFamily: SERIF
  },
  heading04: {
    fontSize: 12,
    lineHeight: 18 / 12,
    fontFamily: SERIF,
    fontStyle: 'italic'
  },
  heading05: {
    fontSize: 20,
    lineHeight: 15 / 15,
    fontFamily: SANS
  },
  heading06: {
    fontSize: 16,
    lineHeight: 20 / 16,
    fontFamily: SERIF
  },
  heading07: {
    fontSize: 16,
    lineHeight: 16 / 16,
    fontFamily: SANS
  },
  heading08: {
    fontSize: 20,
    lineHeight: 27 / 20,
    fontFamily: SERIF
  },
  heading09: {
    fontSize: 14,
    lineHeight: 20 / 14,
    fontFamily: SERIF,
    fontStyle: 'italic'
  },
  heading10: {
    fontSize: 18,
    lineHeight: 18 / 12,
    fontFamily: SANS
  },
  heading11: {
    fontSize: 14,
    lineHeight: 18 / 12,
    fontFamily: SANS
  },
  heading13: {
    fontSize: 21,
    lineHeight: 27 / 21,
    fontFamily: SERIF
  },
  heading14: {
    fontSize: 15,
    lineHeight: 20 / 14,
    fontFamily: SERIF,
    fontStyle: 'italic'
  },
  heading15: {
    fontSize: 20,
    lineHeight: 27 / 20,
    fontFamily: SERIF,
    fontStyle: 'italic'
  },
  heading16: {
    fontSize: 28,
    lineHeight: 40 / 28,
    fontFamily: SANS,
    fontWeight: 400
  },
  heading17: {
    fontSize: 150,
    lineHeight: 150 / 150,
    fontFamily: SERIFALTERNATIVE,
    letterSpacing: 0.2
  },
  heading18: {
    fontSize: 120,
    lineHeight: 150 / 120,
    fontFamily: SERIFALTERNATIVE,
    letterSpacing: 0.2
  },
  heading19: {
    fontSize: 60,
    lineHeight: 62 / 60,
    fontFamily: SERIFALTERNATIVE,
    letterSpacing: 0.2
  },
  heading20: {
    fontSize: 40,
    lineHeight: 53 / 40,
    fontFamily: SERIF,
    fontStyle: 'italic',
    letterSpacing: 0.2
  },
  body01: {
    fontSize: 11,
    lineHeight: 20 / 11,
    fontFamily: SANS
  },
  body02: {
    fontSize: 12,
    lineHeight: 18 / 12,
    fontFamily: SANS
  },
  label01: {
    fontSize: 10,
    lineHeight: 10 / 10,
    fontFamily: SANS
  },
  input01: {
    fontSize: 16,
    lineHeight: 25 / 13,
    fontFamily: SANS
  },
  input02: {
    fontSize: 13,
    lineHeight: 25 / 13,
    fontFamily: SANS
  },

  ////////////////////////////

  italic: {
    fontFamily: SERIF
  }
}

export const getFontStyles = (fontType) => fonts[fontType]
