export const screenSizes = {
  sm: 420,
  md: 740,
  lg: 1000,
  xl: 1300,
  xxl: 1900
}

export const breakpoints = Object.entries(screenSizes).reduce((acc, [name, size]) => {
  return {
    ...acc,
    [name]: `(min-width: ${size.toString()}px)`
  }
}, {})
