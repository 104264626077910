import { createStitches } from '@stitches/react'
import { colors } from './colors'
import { sizes } from './sizes'
import { getFontStyles } from './fonts'
import { breakpoints } from './breakpoints'
import { lin } from './lin.ts'
import { containerMargin } from './containerMargin'

const stitches = createStitches({
  theme: {
    colors,
    space: sizes,
    sizes
  },
  media: breakpoints,
  utils: {
    // Abbreviated margin properties
    m: (value) => ({ margin: value }),
    mt: (value) => ({ marginTop: value }),
    mr: (value) => ({ marginRight: value }),
    mb: (value) => ({ marginBottom: value }),
    ml: (value) => ({ marginLeft: value }),
    mx: (value) => ({ marginLeft: value, marginRight: value }),
    my: (value) => ({ marginTop: value, marginBottom: value }),
    // Abbreviated padding properties
    p: (value) => ({ padding: value }),
    pt: (value) => ({ paddingTop: value }),
    pr: (value) => ({ paddingRight: value }),
    pb: (value) => ({ paddingBottom: value }),
    pl: (value) => ({ paddingLeft: value }),
    px: (value) => ({ paddingLeft: value, paddingRight: value }),
    py: (value) => ({ paddingTop: value, paddingBottom: value }),
    bg: (value) => ({
      background: value
    }),
    projectFont: getFontStyles,
    lin,
    containerMargin
  }
})

export const { styled, css, globalCss, keyframes, getCssText, theme, createTheme, config } = stitches
