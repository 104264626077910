export const sizes = {
  0: '0px',
  1: '1px',
  2: '2px',
  3: '4px',
  4: '6px',
  5: '8px',
  6: '12px',
  7: '16px',
  8: '24px',
  9: '32px',
  10: '48px',
  11: '64px',
  12: '96px',
  13: '128px',
  14: '160px',
  15: '220px',
  16: '280px'
}
