/**
 * Convention for color naming is tailwind-like with a _ suffix describing opacity
 */
export const colors = {
  black: '#000',
  white: '#fff',
  cream: '#FCFAF7',
  cream400: '#F5F3EF',
  cream500: '#F5F2EE',
  cream700: '#EFECE7',
  cream800: '#EDEAE6',
  red: '#CD3105',
  imageBg: '#F5F2ED',
  mono50: '#fafafa',
  mono100: '#f5f5f5',
  mono200: '#eeeeee',
  mono300: '#e0e0e0',
  mono400: '#bdbdbd',
  mono500: '#9e9e9e',
  mono600: '#757575',
  mono700: '#616161',
  mono800: '#424242',
  mono900: '#212121'
}
