export const containerMargin = (prop) => {
  return {
    [`${prop}`]: '24px',
    '@sm': {
      [`${prop}`]: '50px'
    },
    '@md': {
      [`${prop}`]: '46px'
    },
    '@lg': {
      [`${prop}`]: '64px'
    }
  }
}
